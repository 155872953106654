import { API_ROOT } from '@constants/environment';
import { isValidURL } from '@devfolioco/helpers';
import type { ParseGoogleAuthorizationCodeResponse } from '@devfolioco/api-types';
import defaultAxios from 'axios';
import type { AxiosRequestConfig, AxiosResponse } from 'axios';

import { logOut } from '@actions/authentication';
import { history } from '@helpers/history';
import { apiErrorStatus } from '@helpers';
import { getUTCDateFromMonthAndYear } from '@helpers/date';
import { CurrentDateTime } from '@devfolioco/api-types';
import { SERVER_PARAM, BETA_FEATURE_FLAG_UUID } from '@constants';

import { UserEligibleForQuadraticVoting } from '@devfolioco/api-types/composed';
import { ReportResponseI } from '@components/AIAssessmentProjectModal/types';
import store from '../../shared/store';
import { errorHandler } from './axios';

const EXTRA_ATTRIBUTES_QUERY =
  'user_hackathon_extra_attributes=uuid,value&hackathon_scope_extra_attributes=uuid,name,required';

const HEADER_OPTIONS = {
  headers: {
    'CSRF-Token': store && store.getState().quiz.csrfToken,
  },
};

const axios = defaultAxios.create({ withCredentials: true });

/**
 * This is an axios response interceptor which handles the access token
 * refresh mechanism, and logs a user out incase access_token header
 * is absent in the header of a request
 */
axios.interceptors.response.use(
  response => response,
  error => {
    const { response } = error;

    if (response?.status === 401 || response?.status === 403) {
      // Log the user out in any other case (when the token has not expired)
      if (typeof window !== 'undefined') {
        dispatchLogOutAndRoute();
      }
    }

    if (apiErrorStatus.internalServerError(error)) {
      store.dispatch({ type: 'SERVER_ERROR' });
    }

    return errorHandler(error);
  }
);

const dispatchLogOutAndRoute = () => {
  store.dispatch(logOut());

  history.push({
    pathname: '/signin',
  });
};

const requests = {
  delete: (url: string) => axios.delete(`${API_ROOT}${url}`),
  deleteWithData: (url: string, data) => axios.delete(`${API_ROOT}${url}`, data),
  get: <T = any>(url: string, params?: any) => axios.get<T>(`${API_ROOT}${url}`, params),
  patch: (url: string, body?: any) => axios.patch(`${API_ROOT}${url}`, body),
  post: (url: string, body?: any, config?: AxiosRequestConfig) => axios.post(`${API_ROOT}${url}`, body, config),
  put: (url: string, body?: any) => axios.put(`${API_ROOT}${url}`, body),
};

export const API = {
  // Authentication APIs
  authentication: {
    // Refresh the Token
    getNewAccessToken: refreshToken =>
      axios.post(`${API_ROOT}users/token`, null, {
        headers: {
          refresh_token: refreshToken,
        },
      }),

    // User Login
    signIn: (username: string, password: string, captcha: string) =>
      requests.post('users/signin', {
        username,
        password,
        captcha_response: captcha,
      }),

    // User Logout
    logOut: () => requests.post('users/logout'),

    // Checks if the google ID belongs to a user
    googleIsIntegrated: (googleId: string) =>
      requests.post('users/google/registered', {
        google_id: googleId,
      }),

    // Sign in with a google ID, and google Access Token
    googleSignIn: (googleIDToken: string) =>
      requests.post('users/signin/google', {
        google_id_token: googleIDToken,
      }),

    getGoogleIdToken: (googleAuthCode: string) =>
      requests.post('users/auth/google/token', {
        code: googleAuthCode,
      }) as Promise<AxiosResponse<ParseGoogleAuthorizationCodeResponse>>,

    // Email verification
    verifyEmail: (token: string) =>
      requests.post('users/verifyEmail', {
        token,
      }),

    // To get the password reset link in the user email
    forgotPassword: (email: string) =>
      requests.post('emails/forgotPassword', {
        email,
      }),

    // Password reset request
    resetPassword: (password: string, token: string) =>
      requests.post('users/resetPassword', {
        password,
        token,
      }),

    // Update the user password
    changePassword: (username: string, oldPassword: string, newPassword: string) =>
      requests.post(`users/${username}/update_password`, {
        password: oldPassword,
        new_password: newPassword,
      }),
  },

  // User Registration APIs
  registration: {
    // Finds if the Google ID is associated with any account
    isGoogleAssociated: (googleID: string) =>
      requests
        .post(`users/google/registered`, {
          google_id: googleID,
        })
        .then(({ data }) => data.registered),

    // Finds if the email is associated with any account
    isEmailAssociated: (email: string) =>
      requests
        .post(`emails/available`, {
          email,
        })
        .then(({ data }) => !data.email_available),

    // Obtain information about the email
    getEmailProfile: (email: string) =>
      requests.get(`emails/score?email=${encodeURI(email)}`).then(({ data }) => ({
        didYouMean: data.did_you_mean,
        isDisposable: data.disposable,
        domain: data.domain,
        email: data.email,
        isFormatValid: data.format_valid,
        isFree: data.free,
        isMXValid: data.mx_found,
        role: data.role,
        score: data.score,
        isSMTPValid: data.smtp_check,
        suggestedUsername: data.user,
      })),

    // Check if the username is associated with an account
    isUsernameAvailable: (username: string) =>
      requests
        .get(`users/${username}/available`)
        .then(() => true)
        .catch(() => false),

    // Create a new user account
    registerUser: (username, email, password, referralCode, google: any = null, shouldSubscribeToNewsletter) =>
      requests.post(`users`, {
        email,
        password,
        username,
        mailing_lists_opt_in: shouldSubscribeToNewsletter,
        ref_code: referralCode,
        google_id_token: (google && google.IDToken) || undefined,
      }),

    // Add the first and lastname of the user
    setUserProfile: (username, firstName, lastName) =>
      requests.post(`users/${username}`, {
        first_name: firstName,
        last_name: lastName,
      }),

    // Send the email verification mail
    sendEmailOTP: userObj => requests.post(`emails/verificationEmail`, userObj),

    // The referral request
    sendReferralEmail: (email, referralCode) =>
      requests.post(`emails/sendReferralEmail`, {
        email,
        ref_code: referralCode,
      }),
  },

  // User APIs
  user: {
    // Add experience
    addExperience: (username: string, experience) =>
      requests.post(`users/${username}/experience`, {
        company_domain: (isValidURL(experience.company.domain) && experience.company.domain) || undefined,
        company_name: experience.company.name,
        current_company: experience.current_company,
        description: experience.description,
        end: experience.end || undefined,
        start: experience.start || undefined,
        title: experience.title || undefined,
      }),

    // Delete User
    deleteUser: userUUID => requests.delete(`users/${userUUID}`),
    postDeleteUserFeedbackForm: (deleteToken, reason) =>
      requests.post('users/post_delete_form', { delete_token: deleteToken, reason }),

    // Update work experience
    updateExperience: (username: string, experience) =>
      requests.post(`users/${username}/experience/${experience.uuid}`, {
        company_domain: (isValidURL(experience.company.domain) && experience.company.domain) || undefined,
        company_name: experience.company.name,
        current_company: experience.current_company,
        description: experience.description,
        end: experience.end,
        start: experience.start || undefined,
        title: experience.title || undefined,
      }),

    // Remove work experience
    removeExperience: (username: string, uuid: string) => requests.delete(`users/${username}/experience/${uuid}`),

    // Get all the user data
    fetchUserData: (username: string) => requests.get(`users/${username}`),

    // Get the basic and reuired user data
    fetchBasicUserData: (username: string) => requests.get(`users/${username}/basic_info`),

    // Get extra_info of the user
    fetchUserExtraInfo: (username: string) => requests.get(`users/${username}/extra_info`),

    // Skills search
    searchSkills: (input: string) => requests.get(`skills/search?q=${encodeURI(input)}`),

    // College search
    searchCollege: (input: string) => requests.get(`college?q=${encodeURI(input)}`),

    // Field of Study search
    searchStudyField: (input: string) => requests.get(`users/fieldOfStudy?q=${encodeURI(input)}`),

    // Set user data
    setUserData: (username, param, data) =>
      requests.post(`users/${username}`, {
        [param]: data,
      }),

    // Set user skills
    setUserSkills: (username, skills) =>
      requests.post(`users/${username}/skills`, {
        skills,
      }),

    // Delete the user skill
    deleteSkill: (username, skillID) => requests.delete(`users/${username}/skills/${skillID}`),

    // Set user's domain expertise
    udpateHackerType: (username, hackerType) =>
      requests.post(`users/${username}/userExtras`, {
        domain_expertise: hackerType,
      }),

    // Update where the user has formal education or not
    setHasEducation: (username, hasFormalEducation) =>
      requests.post(`users/${username}/userExtras`, {
        is_education: hasFormalEducation,
      }),

    // Update where the user has experience or not
    setHasExperience: (username, hasNoWorkExperience) =>
      requests.post(`users/${username}/userExtras`, {
        no_work_exp: hasNoWorkExperience,
      }),

    // Get resume signed upload URL
    getResumeUploadURL: username => requests.get(`users/${username}/resumeSignedUrl?file_type=pdf`),

    getResumeViewURL: (username, url) =>
      /**
       * Obtains the DO temporary resume link
       * @param {username} - username of the user
       * @param {url} - the URL of the stored resume
       */
      requests.get(`users/${username}/resume/?url=${encodeURI(url)}`),

    // Link a profile
    addProfile: (username, { name, value, profileUsername = undefined, socialID = undefined, token = undefined }) =>
      requests.post(`users/${username}/profile`, {
        value,
        name,
        social_id: socialID,
        username: profileUsername,
        token,
      }),

    // Remove a linked profile
    removeProfile: (username, uuid) => requests.delete(`users/${username}/profile/${uuid}`),

    // Add a college
    addCollege: college =>
      requests.post(`college`, {
        ...college,
      }),

    // Send the user education data
    addEducation: (
      username,
      { degreeType, educationInstitution, fieldOfStudy, isStudent, monthOfGraduation, yearOfGraduation }
    ) =>
      requests.post(`users/${username}/education`, {
        college_id: educationInstitution.uuid,
        current_college: isStudent,
        degree_type: degreeType,
        education_field_id: fieldOfStudy.uuid,
        graduation_year: getUTCDateFromMonthAndYear(monthOfGraduation, yearOfGraduation),
        is_education: true,
      }),

    // Update the user education data
    updateEducation: (
      degreeType,
      educationID,
      educationInstitution,
      fieldOfStudy,
      isStudent,
      monthOfGraduation,
      username,
      yearOfGraduation
    ) =>
      requests.post(`users/${username}/education/${educationID}`, {
        college_id: educationInstitution.uuid,
        current_college: isStudent,
        degree_type: degreeType,
        education_field_id: fieldOfStudy.uuid,
        graduation_year: getUTCDateFromMonthAndYear(monthOfGraduation, yearOfGraduation),
        is_education: true,
      }),

    // Delete the user education data
    deleteEducation: (username, uuid) => requests.delete(`users/${username}/education/${uuid}`),

    updateContact: (username, name, value) => requests.post(`users/${username}/contact`, { [name]: value }),

    sendOTP: (username, phoneNumber) =>
      requests.post(`users/${username}/sendOtp`, {
        [SERVER_PARAM.phoneNumber]: phoneNumber,
      }),

    verifyOTP: (username, phoneNumber, otp) =>
      requests.post(`users/${username}/verifyOtp`, {
        otp,
        [SERVER_PARAM.phoneNumber]: phoneNumber,
      }),

    verifyEmailOTP: (email, otp) =>
      requests.post(`users/verifyEmailWithOtp`, {
        otp,
        email,
      }),

    updateEmailAndVerifyOTP: (username, email, otp) =>
      requests.post(`users/${username}/email`, {
        email,
        otp,
      }),

    resendOTP: (username, phoneNumber) =>
      requests.post(`users/${username}/resendOtp`, {
        [SERVER_PARAM.phoneNumber]: phoneNumber,
      }),

    // The the avatar signed URL
    getAvatarUploadURL: (username, fileType) => requests.get(`users/${username}/signedUrlAvatar?file_type=${fileType}`),

    setOpportunities: (username, opportunities) =>
      requests.post(`users/${username}/userExtras`, {
        opportunities,
      }),
    // Updates user_extras
    updateUserExtra: (username, userExtraObject) => requests.post(`users/${username}/userExtras`, userExtraObject),

    // update user contact
    updateUserContact: (username, userContactObject) => requests.post(`users/${username}/contact`, userContactObject),

    // Updates via the user endpoint
    updateUser: (username, userFieldsObject) => requests.post(`users/${username}`, userFieldsObject),

    // update github or linkedin profile
    updateProfile: (username, profileObject) => requests.post(`users/${username}/profile`, profileObject),

    getUserPublicInfo: username => requests.get(`users/${username}/public_profile`),

    updateTourTaken: (username, hasTakenTour) =>
      requests.post(`users/${username}/userExtras`, {
        tour_taken: hasTakenTour,
      }),

    fetchUserStats: username => requests.get(`users/${username}/primary_stats`),

    fetchFeatureFlags: userUUID => requests.get(`users/${userUUID}/feature_flags`),

    joinBeta: userUUID =>
      requests.patch(`users/${userUUID}/feature_flags`, {
        data: [
          {
            op: 'insert',
            value: BETA_FEATURE_FLAG_UUID,
          },
        ],
      }),

    leaveBeta: userUUID =>
      requests.patch(`users/${userUUID}/feature_flags`, {
        data: [
          {
            op: 'remove',
            value: BETA_FEATURE_FLAG_UUID,
          },
        ],
      }),

    getHackathonFileViewURL: (userID, hackathonID, scopeID, fileURL) => {
      if (!fileURL) {
        return null;
      }

      const fileURLSplit = fileURL?.split('.') ?? [];
      const extension = fileURLSplit[(fileURLSplit?.length ?? 1) - 1];

      return requests.get(
        `users/${userID}/hackathon/${hackathonID}/files/${scopeID}?url=${fileURL}&file_type=${extension}`
      );
    },

    connectOAuthProvider: (username: string, provider: string, redirectURL: string) =>
      requests.get(`users/${username}/oauth/${provider}/connect?redirect_url=${encodeURI(redirectURL)}`),

    disconnectOAuthProvider: (username: string, providerUUID: string) =>
      requests.delete(`users/${username}/oauth/${providerUUID}`),

    fetchOAuthUserInfo: (username: string, providerUUID: string) =>
      requests.get(`users/${username}/oauth/${providerUUID}/userInfo`),
  },

  // HACKATHON APIs
  hackathon: {
    applyToHackathon: (username, hackathonID, needHardware, isTravel, isFirstHackathon) =>
      requests.post(`users/${username}/hackathon/${hackathonID}/apply`, {
        need_hardware: needHardware,
        is_travel: isTravel,
        is_first_hackathon: isFirstHackathon,
      }),

    createTeam: (username, hackathonID, name) =>
      requests.post(`users/${username}/hackathon/${hackathonID}/team`, {
        name,
      }),

    deleteTeam: (username, hackathonID, teamID) =>
      requests.deleteWithData(`users/${username}/hackathon/${hackathonID}/deleteTeam`, {
        data: { team_id: teamID },
      }),

    getHackathonScope: (username, hackathonID) => requests.get(`users/${username}/hackathon/${hackathonID}/scope`),

    joinTeam: (username, hackathonID, teamInviteID, code) => {
      if (code !== null) {
        return requests.post(`users/${username}/hackathon/${hackathonID}/joinTeam`, {
          code,
        });
      }
      return new Error();
    },

    leaveTeam: (username, hackathonID, teamID) =>
      requests.deleteWithData(`users/${username}/hackathon/${hackathonID}/leaveTeam`, {
        data: { team_id: teamID },
      }),

    submitApplication: (username, hackathonID) =>
      requests.post(`users/${username}/hackathon/${hackathonID}/submitApplication`),

    // Fetch all the hackathons (paginated)
    fetchHackathonsData: (filter, page, limit = 20) =>
      requests.get(encodeURI(`hackathons?filter=${filter}&page=${page}&limit=${limit}`)),

    // Get a specficic hackathon info
    fetchHackathonData: hackathonID => requests.get(`hackathons/${hackathonID}?field=prizes`),

    fetchHackathonDataWithoutPrizes: hackathonID => requests.get(`hackathons/${hackathonID}`),

    addUserHackathonExtra: (username, hackathonID, scopeID, value) =>
      requests.post(`users/${username}/hackathon/${hackathonID}/scope/${scopeID}`, {
        value,
      }),

    deleteUserHackathonExtra: (username, hackathonID, scopeID) =>
      requests.delete(`users/${username}/hackathon/${hackathonID}/scope/${scopeID}`),

    getUserHackathonExtra: (username, hackathonID) =>
      requests.get(`users/${username}/hackathon/${hackathonID}/details?${EXTRA_ATTRIBUTES_QUERY}`),

    getUserHackathonTracks: hackathonID => requests.get(`hackathons/${hackathonID}/tracks`),

    getHackathonFileUploadURL: (username, hackathonID, scopeID) =>
      requests.get(`users/${username}/hackathon/${hackathonID}/scope/${scopeID}/signedUrl`),

    getHackathonUserDetails: (username, hackathonID) => requests.get(`users/${username}/hackathon/${hackathonID}`),

    getTeamMembers: (username, hackathonID, teamID) =>
      requests.get(`users/${username}/hackathon/${hackathonID}/team/${teamID}/members`),

    submitTeamApplication: (username, hackathonID, teamID) =>
      requests.post(`users/${username}/hackathon/${hackathonID}/team/${teamID}/submit`),

    // Get all the hackathons a user has participated in
    getUserHackathons: username => requests.get(`users/${username}/hackathon`),

    updateHackerStatus: (username, hackathonID, status) =>
      requests.post(`users/${username}/hackathon/${hackathonID}`, {
        status,
      }),

    rsvp: (username, hackathonID) => requests.post(`users/${username}/hackathon/${hackathonID}/rsvp`),

    withdraw: (username, hackathonID) => requests.post(`users/${username}/hackathon/${hackathonID}/withdraw`),

    // Get ticket signed upload URL
    getTicketUploadURL: (username, hackathonID, type) =>
      requests.get(`users/${username}/hackathon/${hackathonID}/ticketSignedUrl?file_type=${type}`),

    // Get ticket view URL
    getTicketViewURL: (username, hackathonID, url) =>
      requests.get(`users/${username}/hackathon/${hackathonID}/ticket?url=${encodeURI(url)}`),

    updateHackerTicket: (username, hackathonID, ticket, isTravel) =>
      requests.post(
        `users/${username}/hackathon/${hackathonID}`,
        Object.assign({
          is_travel: isTravel,
          ticket,
        })
      ),

    createHackathonProject: (username, hackathonID, project) =>
      requests.post(`users/${username}/hackathon/${hackathonID}/project`, project),

    updateHackathonProject: (username, hackathonID, projectId, project) =>
      requests.post(`users/${username}/hackathon/${hackathonID}/project/${projectId}`, project),

    deleteHackathonProject: (username, hackathonID, projectId) =>
      requests.delete(`users/${username}/hackathon/${hackathonID}/project/${projectId}`),

    // Hashtag search
    searchHashtags: input => requests.get(`hashtags/search?q=${encodeURI(input)}`),

    // Get hackathon project pic signed upload URL
    getHackathonProjectImageUploadURL: (username, hackathonID, type, name) =>
      requests.get(`users/${username}/hackathon/${hackathonID}/projectPicSignedUrl?file_type=${type}&name=${name}`),

    // Get hackathon project file signed upload URL
    getHackathonProjectFileUploadURL: (username, hackathonID) =>
      requests.get(`users/${username}/hackathon/${hackathonID}/projectFileSignedUrl`),

    deleteHackathonProjectHashtag: (username, hackathonID, projectID, hashtagID) =>
      requests.delete(`users/${username}/hackathon/${hackathonID}/project/${projectID}/hashtags/${hashtagID}`),

    addProjectTrack: (username, hackathonID, projectID, trackID, description) =>
      requests.post(`users/${username}/hackathons/${hackathonID}/projects/${projectID}/tracks/${trackID}`, {
        description,
      }),

    deleteProjectTrack: (username, hackathonID, projectID, trackID) =>
      requests.delete(`users/${username}/hackathons/${hackathonID}/projects/${projectID}/tracks/${trackID}`),

    updatePaymentDetails: (username, hackathonID, paymentOptions) =>
      requests.post(`users/${username}/hackathon/${hackathonID}`, {
        payment_mode: paymentOptions.mode,
        payment_mode_value: paymentOptions.value,
      }),

    inviteSquad: (username, hackathonSlug, emails, role) =>
      requests.post(`users/${username}/hackathon/${hackathonSlug}/squad`, {
        emails: emails.join(','),
        role,
      }),

    acceptInvite: (username, hackathonSlug, email, token) =>
      requests.post(`hackathons/${hackathonSlug}/squad`, {
        email,
        invite_token: token,
      }),

    fetchAnnouncements: (hackathonSlug, status = '', page = 1, limit = 7) =>
      requests.get(
        `hackathons/${hackathonSlug}/announcements?page=${page}&limit=${limit}${status ? `&${status}` : ''}`
      ),

    updateEmailAnnouncementPreference: (username, hackathonSlug, unsubscribe) =>
      requests.post(`users/${username}/hackathon/${hackathonSlug}/unsubscribe`, {
        unsubscribe_announcement: unsubscribe,
      }),

    fetchFeedback: (username: string, hackathonSlug) =>
      requests.get(`users/${username}/hackathon/${hackathonSlug}/feedbackQuestion`),

    sendFeedback: (username: string, hackathonSlug, feedbackId, value) =>
      requests.post(`users/${username}/hackathon/${hackathonSlug}/feedbackQuestion/${feedbackId}`, {
        value,
      }),

    // Obtain the reimbursement amount via the Travel API
    fetchReimbursementAmount: (username: string, hackathonSlug, city, state, country, page = 1) =>
      requests.get(`users/${username}/hackathon/${hackathonSlug}/fare`, {
        params: {
          city,
          state,
          country,
          page,
        },
      }),

    updateHackerFare: (username: string, hackathonSlug, location) =>
      requests.post(`users/${username}/hackathon/${hackathonSlug}/fare`, {
        city: location.city,
        country: location.country.length ? location.country : undefined,
        state: location.state.length ? location.state : undefined,
      }),

    // Fetch prizes for a hackathon
    fetchHackathonPrizes: hackathonSlug => requests.get(`hackathons/${hackathonSlug}/prizes`),
    // Fetch tracks filled by user for a hackathon project
    fetchProjectTracks: projectSlug => requests.get(`projects/${projectSlug}/tracks`),

    addUserHackathonTrack: (username, hackathonSlug, trackUUID) =>
      requests.post(`users/${username}/hackathon/${hackathonSlug}/tracks`, {
        track_uuid: trackUUID,
      }),
    setTokenproofNonce: (username, hackathonUUID, nonce) =>
      requests.post(`users/${username}/hackathon/${hackathonUUID}/setUserHackathonTokenproofNonce`, {
        nonce,
      }),
    updateTokenproofVerificationStatus: (username, hackathonUUID, nonce) =>
      requests.post(`users/${username}/hackathon/${hackathonUUID}/setTokenproofVerificationStatus`, {
        nonce,
      }),
  },

  project: {
    // Get public project
    getProjectFunding: projectSlug => requests.get(`projects/${projectSlug}/funding`),
    getUserStats: (username: string, projectSlug) => requests.get(`users/${username}/projects/${projectSlug}/comments`),
    getProject: projectSlug => requests.get(`projects/${projectSlug}`),
    getComments: (projectSlug, page = 1, limit = 10, order = 'DESC') =>
      requests.get(`projects/${projectSlug}/stats?page=${page}&limit=${limit}&order=${order}`),
    postComment: (username, projectSlug, comment) =>
      requests.post(`users/${username}/projects/${projectSlug}/comments`, { comment }),
    deleteComment: (username, projectSlug, commentUUID) =>
      requests.delete(`users/${username}/projects/${projectSlug}/comments/${commentUUID}`),
    postLike: (username: string, projectSlug, like) =>
      requests.post(`users/${username}/projects/${projectSlug}/like`, {
        like,
      }),
    createProjectContribution: (projectUUID, amount) =>
      requests.post(`projects/${projectUUID}/contributions`, { amount }), // Sending in paisa
    saveProjectViewer: (projectSlug: string, config: AxiosRequestConfig): Promise<AxiosResponse<any>> =>
      requests.post(`projects/${projectSlug}/save_project_viewer`, undefined, config),
    checkCheerEligibility: (projectUUID: string) => requests.get(`projects/${projectUUID}/check_cheer_eligibility`),
    generateProjectAssessmentReport: (description: string) =>
      requests.post(`projects/assessment_report`, { description }).then(resp => resp.data as ReportResponseI),
  },

  email: {
    // Get the user subscribed mailing lists
    getMailingList: (username: string) => requests.get(`users/${username}/mailing_lists`),

    // Update the subscribed mailing lists
    updateMailingList: (username, mailingListID, subscribed) =>
      requests.post(`users/${username}/mailing_lists/${mailingListID}`, {
        subscribed,
      }),

    // Unsubscribe with a code
    unsubscribe: token => requests.post(`emails/unsubscribe/${token}`),
    resubscribe: token => requests.post(`emails/resubscribe/${token}`),
  },

  form: {
    getForm: (username: string, hackathonSlug, role) => requests.get(`hackathons/${hackathonSlug}/forms?role=${role}`),

    setFormField: (username: string, hackathonSlug, fieldID, value) =>
      requests.post(`users/${username}/hackathon/${hackathonSlug}/scope/${fieldID}`, value),

    submitForm: (username: string, hackathonSlug, role) =>
      requests.post(`users/${username}/hackathon/${hackathonSlug}/submitForm`, { role }),
  },

  ticket: {
    fetchTicketInfo: hackathonSlug => requests.get(`hackathons/${hackathonSlug}/tickets`),

    sendTransactionDetails: (username: string, hackathonSlug, ticketId, tx) =>
      requests.post(`users/${username}/hackathon/${hackathonSlug}/tickets/${ticketId}`, {
        razorpay_payment_id: tx.razorpay_payment_id,
        amount: tx.amount,
      }),
  },

  quiz: {
    fetchPublishedQuizzes: (page = 1) => requests.get(`quiz/published?page=${page}`),

    fetchQuizzes: (username: string, page = 1, limit = 10) =>
      requests.get(`users/${username}/quiz?page=${page}&limit=${limit}`),

    fetchVerifiedSkills: (username: string) => requests.get(`users/${username}/badges`),

    answer: ({ timeTaken = undefined, optionId = undefined }) => {
      const answerOptions: any = {};

      if (timeTaken && optionId) {
        answerOptions.time_taken = timeTaken;
        answerOptions.option_uuid = optionId;
      }

      return axios.post(`quiz`, answerOptions, HEADER_OPTIONS);
    },

    skip: () => {
      return axios.post(`quiz`, { skipped: true }, HEADER_OPTIONS);
    },

    timeout: () => {
      return axios.post(`quiz`, { timeout: true }, HEADER_OPTIONS);
    },
  },

  myProjects: {
    createProject: (username: string, projectObj) =>
      requests.post(`users/${username}/projects`, {
        ...projectObj,
        commit: { commit: 'Created project' },
      }),
    deleteProject: (username: string, projectID) => requests.delete(`users/${username}/projects/${projectID}`),
    fetchAllProjects: (username: string, query) => requests.get(`users/${username}/projects${query}`),
    fetchProject: (username: string, projectSlug) => requests.get(`users/${username}/projects/${projectSlug}`),
    getProjectImageUploadURL: (username: string, projectID, type, name) =>
      requests.get(`users/${username}/projects/${projectID}/picSignedUrl?file_type=${type}&name=${name}`),
    getProjectFileUploadURL: (username: string, projectID) =>
      requests.get(`users/${username}/projects/${projectID}/fileSignedUrl`),
    updateSideProject: (username: string, projectID, project) =>
      requests.post(`users/${username}/projects/${projectID}`, project),
    deleteProjectHashtag: (username: string, projectID, hashtagID) =>
      requests.delete(`users/${username}/projects/${projectID}/hashtags/${hashtagID}`),
  },

  ideas: {
    fetchIdeas: (username: string, page, limit) =>
      requests.get(`users/${username}/projectIdeas?page=${page}&limit=${limit}&order=RAND`),
    fetchIdea: (uuid: string) => requests.get(`ideas/${uuid}`),
    fetchIdeaStats: (uuid: string) => requests.get(`ideas/${uuid}/stats`),
    submitIdea: (username: string, ideaObj: any) => requests.post(`users/${username}/projectIdeas`, ideaObj),
  },

  qv: {
    fetchProjectQVStats: ({ username, hackathonSlug }: { username?: string; hackathonSlug?: string }) =>
      requests.get(`users/${username}/hackathons/${hackathonSlug}/projectQuadraticVotingStats`),
    isUserEligibleForQV: ({ hackathonUUID, userUUID }: { hackathonUUID: string; userUUID: string }) =>
      requests.get<UserEligibleForQuadraticVoting>(
        `users/${userUUID}/hackathon/${hackathonUUID}/isUserEligibleForQuadraticVoting`
      ),
  },

  miscellaneous: {
    getCurrentTime: () => requests.get<CurrentDateTime>('miscellaneous/current_date_time'),
    checkEthIndiaDiscordServerJoined: ({ username, hackathonUUID }: { username: string; hackathonUUID: string }) =>
      requests.get(`users/${username}/hackathon/${hackathonUUID}/ethglobalDiscordStatus`),
    mintBaseHackathonNFT: (username: string, hackathonSlug: string) =>
      requests.post(`users/${username}/hackathon/${hackathonSlug}/mint_campaign_nft`),
  },
};
