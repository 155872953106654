/* eslint-disable max-len */
import invert from 'lodash/invert';
import { ORG_BASE_URL, GIPHY_API_KEY, BASE_URL, NODE_ENV, API_ROOT } from '@constants/environment';

import LinkedIn from '@assets/linkedin.png';
import GitHub from '@assets/github.png';
import Dribbble from '@assets/dribbble.png';
import StackOverflow from '@assets/stackoverflow.png';
import Medium from '@assets/medium.png';
import Instagram from '@assets/instagram.png';
import Kaggle from '@assets/kaggle.png';
import Twitter from '@assets/twitter.png';
import Facebook from '@assets/facebook.png';
import Behance from '@assets/behance.png';
import Topcoder from '@assets/topcoder.png';
import Gmail from '@assets/gmail.png';
import YahooMail from '@assets/yahoomail.png';
import RediffMail from '@assets/rediffmail.png';
import Outlook from '@assets/outlook.png';
import Icloud from '@assets/icloud.png';

import PassStampFrontendSrc from '@assets/pass-stamp-frontend.png';
import PassStampBackendSrc from '@assets/pass-stamp-backend.png';

import SkillVerifiedFrontendSrc from '@assets/skill-verified-frontend.png';
import SkillVerifiedBackendSrc from '@assets/skill-verified-backend.png';

export const STATE = {
  AUTHENTICATION: 'authentication',
  EMAIL: 'email',
  FORM: 'form',
  HACKATHON: 'hackathon',
  PROJECT: 'project',
  QUIZ: 'quiz',
  REGISTRATION: 'registration',
  TICKET: 'ticket',
  USER: 'user',
  MY_PROJECTS: 'myProjects',
} as const;

export const ONE_DAY = 24 * 60 * 60 * 1000; // Milliseconds in a day

export const WAIT_TIME = 400; // Wait time for debounce fn (in ms)

export const GENDER = {
  f: 'Female',
  m: 'Male',
  t: 'Transgender',
  nb: 'Non-binary, genderqueer, or gender non-conforming',
  n: 'Prefer not to say',
} as const;

export const SHIRT_SIZE = {
  xs: 'XS',
  s: 'S',
  m: 'M',
  l: 'L',
  xl: 'XL',
  xxl: 'XXL',
  xxxl: 'XXXL',
} as const;

export const SHIRT_SIZE_DETAILS = {
  s: { size: 'S', chest: 38, length: 26 },
  m: { size: 'M', chest: 40, length: 27 },
  l: { size: 'L', chest: 42, length: 28 },
  xl: { size: 'XL', chest: 44, length: 29 },
  xxl: { size: 'XXL', chest: 46, length: 30 },
  xxxl: { size: 'XXXL', chest: 48, length: 31 },
} as const;

export const DEGREE_TYPE = {
  associate: 'Associate',
  bachelor: 'Bachelors',
  master: 'Masters',
  phd: 'PhD',
  'high school': 'High School',
} as const;

export const HACKER_TYPE = {
  Designer: 'Designer',
  'Frontend Developer': 'Frontend Developer',
  'Backend Developer': 'Backend Developer',
  'Mobile Developer': 'Mobile Developer',
  'Blockchain Developer': 'Blockchain Developer',
  Other: 'Other',
} as const;

export const SCOPES = {
  ABOUT: 'user',
  EDUCATION: 'education',
  SKILLS: 'skills',
  LINKS: 'profiles',
  CONTACT: 'contact',
  OTHER: 'extraScopes',
  USER_HACKATHON: 'user_hackathon',
} as const;

export const SERVER_PARAM = {
  /**
   * Client to Server Param Mapping
   * e.g. firstName => first_name
   */
  accessToken: 'access_token',
  addressLineOne: 'line_1',
  addressLineTwo: 'line_2',
  allergies: 'allergies',
  dietaryPreferences: 'dietary_preferences',
  bio: 'bio',
  city: 'city',
  country: 'country',
  degreeType: 'degree_type',
  dob: 'dob',
  educationID: 'education_id',
  educationInstitution: 'college',
  email: 'email',
  emergencyContactName: 'emergency_contact_name',
  emergencyContactNumber: 'emergency_contact_number',
  ethereumAddress: 'ethereum_address',
  experience: 'experience',
  fieldOfStudy: 'education_field',
  firstName: 'first_name',
  gender: 'gender',
  gitHubProfile: 'github_profile',
  hackerType: 'domain_expertise',
  hasFilledQuestionnaire: 'pref_submitted',
  hasNoFormalEducation: 'is_education',
  hasNoWorkExperience: 'no_work_exp',
  hasTakenTour: 'tour_taken',
  isEmailVerified: 'email_verified',
  isPhoneNumberVerified: 'phone_number_verified',
  isStudent: 'current_college',
  lastName: 'last_name',
  monthOfGraduation: 'graduation_month',
  opportunities: 'opportunities',
  otherProfiles: 'profiles',
  phoneNumber: 'phone_number',
  pincode: 'zip',
  profileImageURL: 'profile_image',
  publicProfileVisible: 'show_public_profile',
  referralCode: 'ref_code',
  referralCount: 'ref_count',
  refreshToken: 'refresh_token',
  resumeURL: 'resume',
  roles: 'roles',
  shirtSize: 't_shirt_size',
  skills: 'user_skill',
  allSkills: 'skills',
  state: 'state',
  userStatus: 'status',
  userID: 'user_id',
  username: 'username',
  yearOfGraduation: 'graduation_year',
  discordChannelJoined: 'discord_channel_joined',
  shortBio: 'short_bio',
} as const;

/**
 * Server to Client Param Mapping
 * e.g. first_name => firstName
 */
export const CLIENT_PARAM = invert(SERVER_PARAM);

/**
 * Client to info mapping
 * e.g. firstName => first name
 */
export const INFO_PARAM = {
  bio: 'bio',
  city: 'city',
  country: 'country',
  degreeType: 'educational degree',
  dob: 'date of birth',
  educationInstitution: 'educational institution',
  email: 'email',
  emergencyContactName: 'emergency contact name',
  emergencyContactNumber: 'emergency contact number',
  experience: 'work experience',
  fieldOfStudy: 'field of study',
  firstName: 'first name',
  gender: 'gender',
  hackerType: 'domain expertise',
  lastName: 'last name',
  monthOfGraduation: 'graduation month',
  otherProfiles: 'online profile links',
  phoneNumber: 'phone number',
  resumeURL: 'resume',
  shirtSize: 't-shirt size',
  skills: 'skills',
  allSkills: 'skills',
  state: 'state',
  username: 'username',
  yearOfGraduation: 'graduation year',
  ethereumAddress: 'Ethereum Wallet',
} as const;

export const INFO_TITLE = {
  [SCOPES.ABOUT]: 'About',
  [SCOPES.EDUCATION]: 'Education',
  [SCOPES.SKILLS]: 'Experience',
  [SCOPES.LINKS]: 'Links',
  [SCOPES.CONTACT]: 'Contact',
} as const;

export const INFO_ICONS = {
  [SCOPES.ABOUT]: 'user',
  [SCOPES.EDUCATION]: 'book',
  [SCOPES.SKILLS]: 'zap',
  [SCOPES.LINKS]: 'link',
  [SCOPES.CONTACT]: 'phone',
} as const;

export const SAVE = {
  /**
   * Saving states. PROGRESS indicates the request has been
   * dispatched, and either SUCCESS or FAILURE could be expected
   */
  FAILURE: "Couldn't Save",
  PROGRESS: 'Saving Changes',
  SUCCESS: 'All Changes Saved',
} as const;

export const RESUME = {
  FAILED: 'Failed',
  READY: 'Ready',
  UPLOADED: 'Uploaded',
  UPLOADING: 'Uploading',
} as const;

export const FILE = RESUME;

export const INTEGRATION = {
  GITHUB: 'github_integration',
} as const;

export const PROFILES = {
  BEHANCE: 'Behance',
  DRIBBBLE: 'Dribbble',
  FACEBOOK: 'Facebook',
  GITHUB: 'GitHub',
  KAGGLE: 'Kaggle',
  LINKEDIN: 'LinkedIn',
  OTHERS: 'other_',
  STACKOVERFLOW: 'Stack Overflow',
  TWITTER: 'Twitter',
} as const;

export const GLOBAL_URLS = {
  CLEARBIT_AUTOCOMPLETE: `${API_ROOT}miscellaneous/clearbit/autocomplete/companies/suggest?query=`,
  ORG_DASH: `${ORG_BASE_URL}`,
  GIPHY: `https://api.giphy.com/v1/gifs/random?api_key=${GIPHY_API_KEY}&tag=excited&rating=g`,
  MX_CHECK: 'https://dns.google.com/resolve?type=MX&name=',
  ORGANIZER_GUIDE:
    'https://paper.dropbox.com/doc/Organize-a-hackathon-with-Devfolio--Ajbs7vM6d~PX2BOsrqqhXKs0Ag-33VmW4kQZ8PZYWZlqpb8A',
} as const;

export const AMZ_ACL = {
  PRIVATE: 'private',
  PUBLIC_READ: 'public-read',
  PUBLIC_READ_WRITE: 'public-read-write',
  AUTHENTICATED_READ: 'authenticated-read',
} as const;

export const OTP = {
  REQUEST_FAILURE: "Could't send OTP!",
  REQUEST_OTP: 'Sending OTP!',
  REQUEST_SUCCESS: 'OTP Sent!',
  REQUEST_RATE_LIMIT_EXCEEDED: 'You have exceeded the number of attempts to send OTP, please try again in an hour.',
  REQUEST_INVALID_NUMBER: 'Please enter a valid phone number.',
  VERIFY_FAILURE: "Could't verify phone number!",
  VERIFY_RATE_LIMIT_EXCEEDED: 'You have exceeded the number of attempts to verify OTP, please try again in an hour.',
  VERIFY_REQUEST: 'Verifying OTP',
  VERIFY_SUCCESS: 'Succesfully Verified',
} as const;

export const EMAIL_OTP = {
  REQUEST_FAILURE: "Could't send OTP!",
  REQUEST_OTP: 'Sending OTP!',
  REQUEST_SUCCESS: 'OTP Sent!',
  VERIFY_FAILURE: "Could't verify email!",
  VERIFY_REQUEST: 'Verifying OTP',
  VERIFY_SUCCESS: 'Succesfully Verified',
} as const;

export const APPLY_STATUS = {
  NOT_SUBMIT: null,
  ACCEPT_SENT: 'accept_sent',
  ACCEPT: 'accept',
  CHECK_IN: 'check_in',
  REIMBURSE: 'reimburse',
  REJECT_SENT: 'reject_sent',
  REJECT: 'reject',
  RSVP: 'rsvp',
  SUBMIT: 'submit',
  WAITLIST_SENT: 'waitlist_sent',
  WAITLIST: 'waitlist',
  WITHDRAW: 'withdraw',
} as const;

export const ACTION_STATUS = {
  FAILURE: 'Request Failed',
  READY: 'Ready',
  REQUEST: 'In Process',
  SUCCESS: 'Request Successful',
} as const;

export const INVERT_SCOPES = invert(SCOPES);

export const SUBMISSION_LINK_ACTIVITY = (eventType: string) => ({
  HACKATHON_LIVE_CAN_SUBMIT: 'Team admin can submit the project on behalf of the entire team.',
  HACKATHON_LIVE_CANNOT_SUBMIT:
    "Team admin can submit the project on behalf of the entire team after they're all checked in.",
  HACKATHON_NOT_LIVE_CANNOT_SUBMIT: `Link to submit project will be active throughout the ${eventType}.`,
  HACKATHON_LIVE_PROJECT_IN_DRAFT: 'Your project is in draft. Ensure to submit it before hackathon ends.',
});

export const SECTION = {
  ABOUT: 'About',
  EDUCATION: 'Education',
  SKILLS: 'Skills',
  LINKS: 'Links',
  CONTACT: 'Contact',
} as const;

export const SECTION_PARAMS = {
  ABOUT: {
    bio: 'bio',
    dob: 'dob',
    firstName: 'first_name',
    gender: 'gender',
    lastName: 'last_name',
    shirtSize: 't_shirt_size',
  },
  EDUCATION: {
    hasNoFormalEducation: 'is_education',
    degreeType: 'degree_type',
    educationInstitution: 'college',
    isStudent: 'current_college',
    fieldOfStudy: 'education_field',
    yearOfGraduation: 'graduation_year',
  },
  SKILLS: {
    hackerType: 'domain_expertise',
    experience: 'experience',
    resumeURL: 'resume',
    skills: 'user_skill',
  },
  LINKS: {
    otherProfile: 'other',
    githubProfile: 'GitHub',
  },
  CONTACT: {
    addressLineOne: 'line_1',
    city: 'city',
    country: 'country',
    emergencyContactName: 'emergency_contact_name',
    emergencyContactNumber: 'emergency_contact_number',
    phoneNumber: 'phone_number',
    pincode: 'zip',
    state: 'state',
  },
  HACKATHON: {
    applyMode: 'apply_mode',
    coverImg: 'cover_img',
    desc: 'desc',
    endsAt: 'ends_at',
    isOnline: 'is_online',
    location: 'location',
    name: 'name',
    startsAt: 'starts_at',
    status: 'status',
    tagline: 'tagline',
    teamSize: 'team_size',
    themed: 'themed',
    timezone: 'timezone',
    url: 'uri',
    uuid: 'uuid',
    verified: 'verified',
  },
} as const;

export const CARDS = {
  BASIC_INFORMATION: {
    dob: 'dob',
    firstName: 'first_name',
    gender: 'gender',
    lastName: 'last_name',
    shirtSize: 't_shirt_size',
  },
  CONTACT: {
    city: 'city',
    country: 'country',
    addressLineOne: 'line_1',
    state: 'state',
    pincode: 'zip',
  },
  EMERGENCY_CONTACT: {
    emergencyContactName: 'emergency_contact_name',
    emergencyContactNumber: 'emergency_contact_number',
  },
} as const;

export const APPLY_MODE = {
  TEAM: 'team',
  INDIVIDUAL: 'individual',
  BOTH: 'both',
} as const;

export const CREATE_TEAM = {
  DUPLICATE: 'Team name is taken',
  DENIED: 'Cannot create a team',
  REQUEST: 'Creating a team ...',
  SUCCESS: 'Successfully created team',
} as const;

export const SERVER_INPUT_TYPE = {
  BOOL: 'bool',
  CHECKBOX: 'checkbox',
  DROPDOWN: 'dropdown',
  FILE: 'file',
  INPUT: 'input',
  MULTI_SELECT: 'multi_select',
  TEXTAREA: 'textarea',
  TRUE_ONLY: 'true_only',
} as const;

export const MAX_DESC_ITEM_CHARS = 2000;
export const MAX_LINK_CHARS = 999;
export const MAX_LINKS = 5;
export const MAX_NAME_CHARS = 50;
export const MAX_PIC = 5;
export const MAX_TAG_CHARS = 199;
export const MAX_TAGLINE_CHARS = 200;
export const MAX_TAGS = 10;
export const MAX_VIDEO_CHARS = 2000;

export const MAILING_LIST_TYPE = {
  NORMAL: 'normal',
  OPPORTUNITY: 'opportunity',
} as const;
export const ROLE = {
  GUEST: 'guest',
  HACKER: 'hacker',
  ORGANIZER: 'organizer',
  SQUAD: 'squad',
  ROOT: 'root',
} as const;

export const HACKATHON_STATUS = {
  PUBLISH: 'publish',
  DRAFT: 'draft',
} as const;

export const STATUS_COLOR = {
  OFFLINE: {
    null: 'white-0',
    accept_sent: 'white-0',
    accept: 'white-0',
    submit: 'white-0',
    reject: 'white-0',
    reject_sent: 'white-0',
    withdraw: 'grey-0',
    waitlist: 'white-0',
    waitlist_sent: 'white-0',
    rsvp: 'white-0',
    check_in: 'white-0',
    reimburse: 'white-0',
  },
  ONLINE: {
    publish: 'white-0',
    draft: 'white-0',
    project_not_created: 'white-0',
    null: 'white-0',
  },
} as const;

export const STATUS_BACKGROUND_COLOR = {
  OFFLINE: {
    null: 'red-4',
    accept_sent: 'gold-0',
    accept: 'gold-0',
    submit: 'gold-0',
    reject: 'gold-0',
    reject_sent: 'red-4',
    withdraw: 'grey-7',
    waitlist: 'gold-0',
    waitlist_sent: 'blue-4',
    rsvp: 'gold-0',
    check_in: 'gold-0',
    reimburse: 'gold-0',
  },
  ONLINE: {
    publish: 'teal-5',
    draft: 'blue-4',
    project_not_created: 'yellow-6',
    null: 'red-5',
  },
} as const;

export const STATUS_LABEL = {
  OFFLINE: {
    null: 'Not Submitted For Review',
    accept_sent: 'Accepted',
    accept: 'Under Review',
    submit: 'Under Review',
    reject: 'Under Review',
    reject_sent: 'Not Accepted',
    withdraw: 'Withdrawn',
    waitlist: 'Under Review',
    waitlist_sent: 'Waitlisted',
    rsvp: 'Accepted',
    check_in: 'Accepted',
    reimburse: 'Accepted',
  },
  ONLINE: {
    publish: 'Project Submitted',
    draft: 'Edit Project',
    project_not_created: 'No Project created',
    null: 'Application Not Submitted',
  },
} as const;

export const LOCATION = { lat: 28.6448, lng: 77.216721 } as const;

export const PROFILES_ARRAY = [
  { name: 'LinkedIn', url: 'https://linkedin.com', logo: LinkedIn },
  { name: 'GitHub', url: 'https://github.com', logo: GitHub },
  { name: 'GitHub Pages', url: 'https://github.io', logo: GitHub },
  { name: 'Medium', url: 'https://medium.com', logo: Medium },
  { name: 'Dribbble', url: 'https://dribbble.com', logo: Dribbble },
  { name: 'Kaggle', url: 'https://kaggle.com', logo: Kaggle },
  { name: 'Stack Overflow', url: 'https://stackoverflow.com', logo: StackOverflow },
  { name: 'StackOverflow', url: 'https://stackoverflow.com', logo: StackOverflow },
  { name: 'Instagram', url: 'https://instagram.com', logo: Instagram },
  { name: 'Twitter', url: 'https://twitter.com', logo: Twitter },
  { name: 'Facebook', url: 'https://facebook.com', logo: Facebook },
  { name: 'Behance', url: 'https://behance.net', logo: Behance },
  { name: 'Topcoder', url: 'https://topcoder.com', logo: Topcoder },
] as const;

export const EMAIL_DOMAINS = [
  {
    name: 'Gmail',
    logo: Gmail,
    domain: 'gmail.com',
    mx_domain: 'google.com.',
  },
  {
    name: 'Yahoo! Mail',
    logo: YahooMail,
    domain: 'yahoo.com',
    mx_domain: 'yahoodns.com.',
  },
  {
    name: 'Yahoo! Mail',
    logo: YahooMail,
    domain: 'ymail.com',
    mx_domain: 'yahoodns.com.',
  },
  {
    name: 'Outlook',
    logo: Outlook,
    domain: 'outlook.com',
    mx_domain: 'outlook.com.',
  },
  {
    name: 'Outlook',
    logo: Outlook,
    domain: 'live.com',
    mx_domain: 'outlook.com.',
  },
  {
    name: 'Outlook',
    logo: Outlook,
    domain: 'hotmail.com',
    mx_domain: 'outlook.com.',
  },
  {
    name: 'iCloud',
    logo: Icloud,
    domain: 'icloud.com',
    mx_domain: 'icloud.com.',
  },
  {
    name: 'Rediffmail',
    logo: RediffMail,
    domain: 'rediffmail.com',
    mx_domain: 'akadns.net.',
  },
] as const;

export const RELATIONSHIP = [
  'Spouse',
  'Mother',
  'Father',
  'Sibling',
  'Grandparent',
  'Child',
  'Caretaker',
  'Other',
] as const;
export const FORM_TYPE = {
  volunteer: {
    name: 'Volunteer Signup',
    description:
      'Volunteer responsibilities include event setup & take-down, signing-in & directing attendees, assisting speakers & sponsors, supervising workspaces',
  },
  mentor: {
    name: 'Mentorship Signup',
    description: `Thanks for your interest in being a mentor! Before we can confirm your attendance, we just need some
    information about you and some context on the kinds of skills and experience you bring to the hackers at the
    event.

    Spread the word: Do you know hackers who might want to join? Do you know folks who would make a great mentor?
    Any groups or meetups who could share info about us? This is a community event — please spread the word!`,
  },
  sponsor: {
    name: 'Representatives RSVP',
    description: '',
  },
  speaker: {
    name: 'Call for Proposals',
    description:
      'Interested in sharing your experience and knowledge with new entrants and enthusiasts in the space? Submit your proposal for a workshop, tech talk, or panel to make sure your voice is heard at the biggest #ETHGlobal event of the year!',
  },
  hacker: {
    name: 'Hacker Form',
    description:
      "Asia's biggest Ethereum hackathon | 2-4 August, 2019 in MLR Convention Centre, JP Nagar, Bangalore!  #BUIDL #Ethereum #ETHIndia",
  },
} as const;

export const FOOD_RESTRICTIONS = [
  'Gluten-free',
  'Halal',
  'Keto',
  'Kosher',
  'Lactose-free',
  'Pescatarian',
  'Vegan',
  'Vegetarian',
  'None',
] as const;

export const CHECK_FIELDS = [
  'dob',
  'state',
  'bio',
  'firstName',
  'shirtSize',
  'lastName',
  'phoneNumber',
  'email',
] as const;

export const CHECK_PROFILE_FIELDS = ['gitHubProfile', 'linkedInProfile'] as const;

export const CHECK_EXTRA_FIELDS = [
  'allergies',
  'emergencyContactEmail',
  'emergencyContactRelationship',
  'foodRestrictions',
] as const;

export const CHECK_CONTACT_FIELDS = [
  'city',
  'emergencyContactName',
  'country',
  'emergencyContactNumber',
  'addressLineOne',
] as const;

export const STATIC_SECTIONS = {
  volunteer: [
    {
      order: 1,
      name: 'First Name',
      section: 'ABOUT',
      required: true,
      type: SERVER_INPUT_TYPE.INPUT,
      uuid: 'firstName',
    },
    {
      order: 2,
      name: 'Last Name',
      section: 'ABOUT',
      required: true,
      type: SERVER_INPUT_TYPE.INPUT,
      uuid: 'lastName',
    },
    {
      order: 3,
      name: 'Email',
      section: 'ABOUT',
      required: true,
      type: SERVER_INPUT_TYPE.INPUT,
      disabled: true,
      uuid: 'email',
    },
    {
      order: 4,
      name: 'Phone Number',
      section: 'ABOUT',
      required: true,
      type: 'PhoneNumber',
      uuid: 'phoneNumber',
    },
  ],
  mentor: [
    {
      order: 1,
      name: 'First Name',
      section: 'ABOUT',
      required: true,
      type: SERVER_INPUT_TYPE.INPUT,
      uuid: 'firstName',
    },
    {
      order: 2,
      name: 'Last Name',
      section: 'ABOUT',
      required: true,
      type: SERVER_INPUT_TYPE.INPUT,
      uuid: 'lastName',
    },
    {
      order: 3,
      name: 'Email',
      section: 'ABOUT',
      required: true,
      type: SERVER_INPUT_TYPE.INPUT,
      disabled: true,
      uuid: 'email',
    },
    {
      order: 4,
      name: 'Phone Number',
      section: 'ABOUT',
      required: true,
      type: 'PhoneNumber',
      uuid: 'phoneNumber',
    },
    {
      order: 5,
      name: 'Date of Birth',
      section: 'ABOUT',
      required: true,
      type: 'Date',
      uuid: 'dob',
    },
    {
      order: 6,
      name: 'Food Restrictions',
      section: 'ABOUT',
      required: true,
      type: 'multi_select',
      value: FOOD_RESTRICTIONS.join(','),
      uuid: 'foodRestrictions',
    },
    {
      order: 7,
      name: 'Do you have any allergies? ',
      section: 'ABOUT',
      required: false,
      type: SERVER_INPUT_TYPE.INPUT,
      uuid: 'allergies',
    },
    {
      order: 8,
      name: 'Please link your GitHub account',
      section: 'ABOUT',
      required: true,
      placeholder: 'https://www.github.com/devfolioco',
      type: 'Link',
      uuid: 'gitHubProfile',
    },
    {
      order: 9,
      name: 'Do you have a LinkedIn profile? If so, please link below',
      section: 'ABOUT',
      required: false,
      placeholder: 'https://www.linkedin.com/in/devfolioco',
      type: 'Link',
      uuid: 'linkedInProfile',
    },
    // {
    //   order: 10,
    //   name: 'What is your T-shirt size?',
    //   section: 'ABOUT',
    //   required: true,
    //   type: 'ShirtSize',
    //   value: Object.keys(SHIRT_SIZE).join(','),
    //   uuid: 'shirtSize',
    // },
    {
      order: 1,
      name: 'Emergency Contact Name',
      section: 'CONTACT',
      required: true,
      type: SERVER_INPUT_TYPE.INPUT,
      uuid: 'emergencyContactName',
    },
    {
      order: 2,
      name: 'Emergency Contact Email',
      section: 'CONTACT',
      required: true,
      type: SERVER_INPUT_TYPE.INPUT,
      uuid: 'emergencyContactEmail',
    },
    {
      order: 3,
      name: 'Emergency Contact Number',
      section: 'CONTACT',
      required: true,
      type: 'PhoneNumber',
      uuid: 'emergencyContactNumber',
    },
    {
      order: 4,
      name: 'Emergency Contact Relationship',
      section: 'CONTACT',
      required: true,
      type: 'checkbox',
      value: RELATIONSHIP.join(','),
      uuid: 'emergencyContactRelationship',
    },
    {
      order: 5,
      name: 'Address Line 1',
      section: 'CONTACT',
      required: true,
      type: SERVER_INPUT_TYPE.INPUT,
      uuid: 'addressLineOne',
    },
    {
      order: 6,
      name: 'City',
      section: 'CONTACT',
      required: true,
      type: 'City',
      uuid: 'city',
    },
    {
      order: 7,
      name: 'Country',
      section: 'CONTACT',
      required: true,
      type: 'Country',
      uuid: 'country',
    },
  ],
  sponsor: [
    {
      order: 1,
      name: 'First Name',
      section: 'ABOUT',
      required: true,
      type: SERVER_INPUT_TYPE.INPUT,
      uuid: 'firstName',
    },
    {
      order: 2,
      name: 'Last Name',
      section: 'ABOUT',
      required: true,
      type: SERVER_INPUT_TYPE.INPUT,
      uuid: 'lastName',
    },
    {
      order: 3,
      name: 'Email',
      section: 'ABOUT',
      required: true,
      type: SERVER_INPUT_TYPE.INPUT,
      disabled: true,
      uuid: 'email',
    },
    {
      order: 4,
      name: 'Phone Number',
      section: 'ABOUT',
      required: true,
      type: 'PhoneNumber',
      uuid: 'phoneNumber',
    },
    {
      order: 5,
      name: 'Date of Birth',
      section: 'ABOUT',
      required: true,
      type: 'Date',
      uuid: 'dob',
    },
    {
      order: 6,
      name: 'Food Restrictions',
      section: 'ABOUT',
      required: true,
      type: 'multi_select',
      value: FOOD_RESTRICTIONS.join(','),
      uuid: 'foodRestrictions',
    },
    {
      order: 7,
      name: 'Do you have any allergies? ',
      section: 'ABOUT',
      required: false,
      type: SERVER_INPUT_TYPE.INPUT,
      uuid: 'allergies',
    },
    {
      order: 1,
      name: 'Emergency Contact Name',
      section: 'CONTACT',
      required: true,
      type: SERVER_INPUT_TYPE.INPUT,
      uuid: 'emergencyContactName',
    },
    {
      order: 2,
      name: 'Emergency Contact Email',
      section: 'CONTACT',
      required: true,
      type: SERVER_INPUT_TYPE.INPUT,
      uuid: 'emergencyContactEmail',
    },
    {
      order: 3,
      name: 'Emergency Contact Number',
      section: 'CONTACT',
      required: true,
      type: 'PhoneNumber',
      uuid: 'emergencyContactNumber',
    },
    {
      order: 4,
      name: 'Emergency Contact Relationship',
      section: 'CONTACT',
      required: true,
      type: 'checkbox',
      value: RELATIONSHIP.join(','),
      uuid: 'emergencyContactRelationship',
    },
  ],
  speaker: [
    {
      order: 1,
      name: 'First Name',
      section: 'ABOUT',
      required: true,
      type: SERVER_INPUT_TYPE.INPUT,
      uuid: 'firstName',
    },
    {
      order: 2,
      name: 'Last Name',
      section: 'ABOUT',
      required: true,
      type: SERVER_INPUT_TYPE.INPUT,
      uuid: 'lastName',
    },
    {
      order: 3,
      name: 'Email',
      section: 'ABOUT',
      required: true,
      type: SERVER_INPUT_TYPE.INPUT,
      disabled: true,
      uuid: 'email',
    },
    {
      order: 4,
      name: 'Phone Number',
      section: 'ABOUT',
      required: true,
      type: 'PhoneNumber',
      uuid: 'phoneNumber',
    },
    {
      order: 5,
      name: 'A brief biography (this will be shared with the hackers on the official agenda)',
      section: 'ABOUT',
      required: true,
      value: null,
      type: SERVER_INPUT_TYPE.TEXTAREA,
      uuid: 'bio',
    },
  ],
} as const;

export const QUIZ_STATUS = {
  QUIZ_PASS: 'quiz_pass',
  QUIZ_FAIL: 'quiz_fail',
  QUIZ_ONGOING: 'quiz_ongoing',
  QUIZ_ATTEMPTS_EXHAUSTED: 'quiz_attempts_exhausted',
  INTERVIEW_SCHEDULED: 'interview_scheduled',
  INTERVIEW_ONGOING: 'interview_ongoing',
  INTERVIEW_PASS: 'interview_pass',
  INTERVIEW_FAIL: 'interview_fail',
  INTERVIEW_DECISION_PENDING: 'interview_decision_pending',
  BOOK_INTERVIEW: 'book_interview',
} as const;

export const HACKATHON_FILTERS = {
  UPCOMING: 'upcoming',
  PAST: 'past',
  LIVE: 'live',
  APPLICATION_OPEN: 'application_open',
  APPLICATION_CLOSE: 'application_close',
  DEVFOLIO_OFFICIAL: 'devfolio_official',
  FEATURED: 'featured',
  ALL: 'all',
  ONLINE: 'online',
} as const;

export const HACKATHON_ROUTES = {
  [HACKATHON_FILTERS.UPCOMING]: 'opening-soon',
  [HACKATHON_FILTERS.ALL]: 'all',
  [HACKATHON_FILTERS.ONLINE]: 'online',
  [HACKATHON_FILTERS.PAST]: 'past',
  [HACKATHON_FILTERS.LIVE]: 'happening-now',
  [HACKATHON_FILTERS.APPLICATION_OPEN]: 'open',
  [HACKATHON_FILTERS.DEVFOLIO_OFFICIAL]: 'devfolio-official',
} as const;

export const QUIZ_PASS_STAMPS = {
  Frontend: PassStampFrontendSrc,
  Backend: PassStampBackendSrc,
} as const;

export const SKILL_VERIFIED_STAMPS = {
  Frontend: SkillVerifiedFrontendSrc,
  Backend: SkillVerifiedBackendSrc,
} as const;

export const QUIZ_ATTEMPTS = {
  NUMBER: 3,
  WORD: 'three',
} as const;

export const EMPTY_EXPERIENCE = {
  company: { name: '', domain: '', logo: '' },
  current_company: false,
  end: null,
  start: null,
  title: '',
  uuid: '',
} as const;

export const SKILL_URLS = {
  Frontend: `${BASE_URL}frontend`,
  Backend: `${BASE_URL}backend`,
} as const;

export const ATTEMPTS_RENEW_AFTER = 'three months';

export const LOGROCKET_ID = 'dtukw7/devfolio';

export const DEVFOLIO_LOGO_URL = 'https://raw.githubusercontent.com/devfolioco/brand-assets/master/Logo/Logo%403x.png';

// get the curret env
export const IS_PROD = NODE_ENV === 'production';

export const AMZ_HEADER = 'x-amz-acl';

export const HACKATHON_LIMIT = 20;

export const YOUTUBE_HOST_NAMES = ['youtube.com', 'youtu.be'];

export const GOOGLE_DRIVE_HOST_NAMES = ['drive.google.com'];

export const VIDEO_HOST_NAMES = [...YOUTUBE_HOST_NAMES, 'vimeo.com', 'dailymotion.com', 'loom.com'];

export const LOOM_HOST_NAME = 'loom.com';

export const PROJECT_DESCRIPTION_QUESTIONS = [
  {
    title: 'The problem it solves',
    subtitle:
      'describe what can people use it for, or how it makes existing tasks easier/safer e.t.c (markdown supported)',
    hint: '',
    content: '',
  },
  {
    title: 'Challenges we ran into',
    subtitle:
      'tell us about any specific bug or hurdle you ran into while building this project. how did you get over it? (markdown supported)',
    hint: '',
    content: '',
  },
] as const;

export const SIGN_IN_TOAST_STATES = {
  PASSWORD_UPDATED: 'PASSWORD_UPDATED',
  USERNAME_UPDATED: 'USERNAME_UPDATED',
  RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
  RESET_PASSWORD_FAILURE: 'RESET_PASSWORD_FAILURE',
  RESET_PASSWORD_TOKEN_EXPIRED: 'RESET_PASSWORD_TOKEN_EXPIRED',
} as const;

// Constant for quadratic funding denominations
export const QUADRATIC_FUNDING_DENOMINATIONS = [50, 100, 500, 1000] as const;

// Feedback options
export const FEEDBACK_OPTIONS = {
  'devfolio-is-no-longer-useful-for-me': 'Devfolio is no longer useful for me',
  'this-was-not-my-primary-devfolio-account': 'This was not my primary Devfolio account',
} as const;

export const ANALYTICS_TRACKING_EVENTS = {
  // Trivial signup events
  TRIVIAL_SIGNUP_ENTER_EMAIL: 'Trivial Signup - enter email',
  TRIVIAL_SIGNUP_WITH_GOOGLE: 'Trivial Signup - google',
  TRIVIAL_SIGNUP_ACCOUNT: 'Trivial Signup - created account',
  TRIVIAL_SIGNUP_NAME: 'Trivial Signup - entered name',
  TRIVIAL_SIGNUP_SKILLS: 'Trivial Signup - updated skills',
  TRIVIAL_SIGNUP_COMPLETE: 'Trivial Signup - completion page',

  // Hackathon tracking events
  ORGANIZE_HACKATHON_CARD_CLICK: 'Organize hackathon card clicked',
  FEATURED_HACKATHON_LINK_CLICK: 'Featured hackathon link clicked',
  FEATURED_HACKATHON_BUTTON_CLICK: 'Featured hackathon button clicked',
  EXPLORE_HACKATHONS_CARD_CLICK: 'Explore hackathon card clicked',
  HACKATHON_CARD_BUTTON_CLICKED: 'Hackathon card button clicked',
  HACKATHON_CARD_LEARN_MORE_CLICKED: 'Hackathon card learn more button clicked',
  MY_HACKATHON_ROW_CLICKED: 'My hackathons row clicked',
  SEARCHED_HACKATHON: 'Searched a hackathon',
  UPDATED_TIME_FRAME_FILTER: 'Updated hackathon timeframe filter',
  SIGNUP_FROM_EXPLORE_HEADER: 'Clicked on explore header signup',

  // Hacker dashboard
  CONTINUE_TO_APPLICATION: 'Continue to the application clicked',
  SMALL_HACKATHON_CARD_CLICK: 'Small hackathon card link clicked',
  SUBMIT_INDIVIDUAL_APPLICATION_OFFLINE: 'Submitted individual application in with review hackathon',
  SUBMIT_TEAM_APPLICATION_OFFLINE: 'Submitted team application in with review hackathon',
  SUBMIT_APPLICATION_ONLINE: 'Submitted application in without review hackathon',
  CHANGE_TAB_ONLINE_HACKATHON: 'Changed tab in without review hackathon',
  WITHDRAW_INDIVIDUAL_APPLICATION_OPEN_MODAL: 'Opened the withdraw individual application modal',
  CONFIRM_WITHDRAW_INDIVIDUAL_APPLICATION: 'Withdrawn individual application',
  OPENED_CODE_OF_CONDUCT: "Opened hackathon's code of conduct URL",
  ACCEPTED_BANNER_GIF: 'Viewed accepted banner GIF',
  YES_TO_RSVP: 'Responded yes to RSVP',
  NO_TO_RSVP_OPEN_MODAL: 'Opened No to RSVP dialog',
  NO_TO_RSVP: 'Confirmed no to RSVP',
  BEGIN_ADDING_PROJECT_ONLINE_HACKATHON: 'Begin adding project without review hackathon',
  SUBMIT_PROJECT_FOR_JUDGING_ONLINE_HACKATHON: 'Submit project for judging without review hackathon',
  EDIT_PROJECT_ONLINE_HACKATHON: 'Edit project without review hackathon',
  DELETE_PROJECT_ONLINE_HACKATHON: 'Delete project without review hackathon',
  ADMIN_SUBMIT_PROJECT_FOR_JUDGING: 'Team admin clicked submit project for judging link in with review hackathon',
  NON_ADMIN_SUBMIT_PROJECT_FOR_JUDGING:
    'Non-Team admin clicked submit project for judging link in with review hackathon',
  BEGIN_SUBMISSION_TO_PROJECT: 'Clicked on begin submission to hackathon',
  PREVIEW_SUBMISSION: 'Previewed hackathon project submission',
  SUBMIT_PROJECT_OFFLINE_HACKATHON: 'Submitted project for with review hackathon',
  SAVE_PROJECT_ONLINE_HACKATHON: 'Saved project without review hackathon',
  VIEWED_SUBMITTED_PROJECT_OFFLINE_HACKATHON: 'Opened submitted project in with review hackathon',
  VIEWED_SUBMITTED_PROJECT_ONLINE_HACKATHON: 'Opened submitted project in without review hackathon',
  ADDED_RATING_FEEDBACK: 'Clicked on a rating feedback',
  SUBMITTED_TEXT_FEEDBACK: 'Submitted the feedback',
  EXPLORE_ONGOING_HACKATHONS: 'Clicked on Explore ongoing hackathons',
  HACKATHON_EMAIL_NOTIFICATION_UNSUBSCRIBE_OPEN_MODAL: 'Opened Unsubscribe from hackathon email notifications modal',
  HACKATHON_EMAIL_NOTIFICATION_UNSUBSCRIBED: 'Unsubscribed from hackathon email notifications',
  HACKATHON_EMAIL_NOTIFICATION_RESUBSCRIBED: 'Resubscribed to hackathon email notifications',

  // Dashboard
  PRIMARY_TAB_CHANGE: 'Clicked on the primary tab in dashboard',
  DROPDOWN_MENU_CLICK: 'Navigated using profile dropdown menu',

  // Projects
  OPENED_PROJECT_FROM_PROJECT_TAB: 'Viewed a project from the project tab',
  CLICKED_ON_UPDATE_PROJECT: 'Clicked on update project button',
  COMMITED_PROJECT_UPDATE: 'Committed project update',
  SHARED_PROJECT_FROM_PROJECT_TAB: 'Shared project from the project tab',
  STARTED_NEW_SIDE_PROJECT: 'Created a new side project',
  CANCELLED_NEW_SIDE_PROJECT: 'Clicked on maybe later in new side project',

  // Idea
  CLICKED_ON_INSPIRE_ME: 'Clicked on inspire me',
  PICKED_AN_IDEA: 'Picked an idea',
  SUBMTTED_AN_IDEA: 'Submitted an idea',

  // Quiz
  QUIZ_THROUGH_SKILLS_TAB: 'Started quiz through skills tab',
  STARTED_QUIZ: 'Started Quiz',
  COMPLETED_QUIZ: 'Completed Quiz',
  RESUMED_QUIZ: 'Resumed Quiz',
  VIEWED_QUIZ_RESOURCES: 'Viewed quiz resources',

  // Profile page
  UPLOADED_RESUME: 'User uploaded resume',
  SORTED_SKILLS: 'User sorted skills in the experience tab',

  // Team actions
  JOIN_TEAM: 'Joined team',
  CREATE_TEAM: 'Created team',
  DELETE_TEAM: 'Deleted team',
  LEAVE_TEAM: 'Left team',

  // Submissions page
  LIKE_PROJECT: 'Clicked on like button on project',
  OPENED_PROJECT_TAG: 'Clicked on a project tag',
  OPENED_HACKATHON_MICROSITE_FROM_PROJECT: 'Opened hackathon microsite from a project',
  POSTED_COMMENT_ON_PROJECT: 'Added a comment on the project',
  REPORTED_PROJECT: 'Reported project',
  OPENED_USER_PROFILE_FROM_PROJECT: 'Clicked on user profile from project',
  OPENED_LINK_ON_PROJECT: 'Clicked on a link of project',

  // My Devfolio
  OPENED_MY_PROFILE_LINK: "Opened one of user profiles from the user's Devfolio",
  COPIED_DEVFOLIO_PROFILE_LINK: 'Copied Devfolio profile link',
  OPENED_SETTINGS_FROM_HIDDEN_PROFILE: 'User opened settings from his hidden Devfolio profile',
  CLICKED_CREATE_YOUR_DEVFOLIO: 'Clicked on create your own Devfolio',

  // Settings
  ADDED_NEW_AVATAR: 'Uploaded new avatar',
  DELETED_AVATAR: 'Deleted avatar',
  TOGGLED_EMAIL_NOTIFICATIONS: 'Toggled email notifications',
  CONFIRMED_HIDE_MY_PROFILE: 'Disabled Devfolio profile from public view',
  MADE_DEVFOLIO_PROFILE_VISIBLE: 'Made Devfolio profile visible to public view',
  OPENED_DELETE_ACCOUNT_MODAL: 'Clicked on delete your account',
  CONFIRMED_ACCOUNT_DELETION: 'Confirmed account delete',
  SUBMITTED_DELETE_ACCOUNT_FEEDBACK: 'Submitted delete account feedback',

  // Trivial Sign in
  TRIVIAL_SIGN_IN: 'Trivial sign in',
  SIGN_IN_WITH_GOOGLE: 'Sign in with google',

  // Register wall events (Sign in/Sign up prefix attached from the register wall)
  REGISTER_WALL_FEATURED_HACKATHON: 'from featured hackathon',
  REGISTER_WALL_HACKATHON_CARD: 'from hackathon card',
  REGISTER_WALL_QUIZ: 'from quiz',
  REGISTER_WALL_IDEA: 'from idea page',
  REGISTER_WALL_SUBMIT_IDEA: 'from submit idea page',

  // Loom recording events
  RECORD_LOOM_START: 'Record video using loom started',
  RECORD_LOOM_CANCEL: 'Record video using loom canceled',
  RECORD_LOOM_COMPLETE: 'Record video using loom complete',

  // AI Events
  PROJECT_SUBMISSION_AI_ANALYSIS_BUTTON_CLICK: 'User clicked the AI Analysis button on the Project Submission Page',
  PROJECT_SUBMISSION_AI_ANALYSIS_REPORT_GENERATED: 'AI Analysis report was generated on the Project Submission Page',
  PROJECT_SUBMISSION_AI_ANALYSIS_REPORT_FAILED: 'AI Analysis report failed generating on the Project Submission Page',
  PROJECT_SUBMISSION_AI_ANALYSIS_FATAL_APPLICATION_CRASH:
    'AI Analysis App threw a fatal error on the Project Submission Page',
} as const;

export const HACKATHON_BUTTON_EVENTS = {
  VIEW_SUBMISSIONS: 'View Submissions',
  GO_TO_DASHBOARD: 'Go to dashboard',
  APPLY_TO_HACKATHON: 'Applied to hackathon',
  GO_TO_ORGANIZER_DASHBOARD: 'Go to organizer dashboard',
} as const;

export const ORDER = {
  DESC: 'DESC',
  ASC: 'ASC',
} as const;

export const PLATFORMS = {
  Web: 'Web',
  Android: 'Android',
  iOS: 'iOS',
  macOS: 'macOS',
  Others: 'Others',
} as const;

export const JUDGING_TYPES = {
  SPONSOR_AND_MAIN_JUDGING: 'Both Finalist Prize Judging and Sponsor Prize Judging',
  SPONSOR_JUDGING: 'Sponsor Prize Judging Only',
};

export const ETHDENVER2025_JUDGING_TYPES = {
  SPONSOR_AND_MAIN_JUDGING: 'Submit to the ETHDenver BUIDLathon + Sponsor Bounties',
  SPONSOR_JUDGING:
    'Submit to Sponsor Bounties Only (Project is not appropriate to be considered for a prize in one of the ETHDenver BUIDLathon Tracks)',
};

// Team types for MumbaiHacks 2024
export const PROJECT_TEAM_TYPE = {
  STUDENT: 'Student Team',
  PROFESSIONAL: 'Professional Team',
};

export const PROJECT_TEAM_TYPE_VALUE = {
  STUDENT: 'STUDENT',
  PROFESSIONAL: 'PROFESSIONAL',
};

export const ALPHA_FEATURE_FLAG_UUID = '83006c2ec7904e3eae7735726124c04c';

export const BETA_FEATURE_FLAG_UUID = '3310fa89f2a542e6a8142398b5e2fbd1';

export const NOTIFICATION_KEY = 'devfolio_notifications';

export const USER_STATUS_DELETED = 'delete';

export const STAKING_TRANSFER_ADDRESS = '0xde2a501AEa5cf9722a77b03a94c50600D9Dbc2b1';

export const POLYGON_STAKING_CONTRACT_ADDRESS = {
  mainnet: '0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
  testnet: '0xA6FA4fB5f76172d178d61B04b0ecd319C5d1C0aa',
};

export const OAUTH_PROVIDERS = {
  GOOGLE: 'google',
  GITHUB: 'github',
  ETHEREUM: 'ethereum',
  DISCORD: 'discord',
  LINKEDIN: 'linkedin',
};

export const OAUTH_PROVIDER_LABELS = {
  [OAUTH_PROVIDERS.GOOGLE]: 'Google',
  [OAUTH_PROVIDERS.GITHUB]: 'GitHub',
  [OAUTH_PROVIDERS.ETHEREUM]: 'Ethereum',
  [OAUTH_PROVIDERS.DISCORD]: 'Discord',
  [OAUTH_PROVIDERS.LINKEDIN]: 'LinkedIn',
};

// ETHINDIA Specific constants

export const PROJECT_CATEGORIES = {
  DeFi: 'DeFi',
  NFTs: 'NFTs',
  Gaming: 'Gaming',
  'DAO Tooling': 'DAO Tooling',
  'Layer 2': 'Layer 2',
  'Wallet/Payments': 'Wallet/Payments',
  'Developer Tool': 'Developer Tool',
  'Data/Analytics': 'Data/Analytics',
  'Audio/Video': 'Audio/Video',
  'Social Network': 'Social Network',
  'Zero Knowledge': 'Zero Knowledge',
  Metaverse: 'Metaverse',
  Other: 'Other',
  'Data DAO': 'Data DAO',
  Lending: 'Lending',
  Infrastructure: 'Infrastructure',
  'Storage Application': 'Storage Application',
  Privacy: 'Privacy',
};

export const PROJECT_TECH_DEVTOOLS = {
  Alchemy: 'Alchemy',
  Alchemyweb3: 'Alchemyweb3',
  Brownie: 'Brownie',
  'Create Eth App': 'Create Eth App',
  Dappsys: 'Dappsys',
  Embark: 'Embark',
  Epirus: 'Epirus',
  Etherlime: 'Etherlime',
  'ethers-rs': 'ethers-rs',
  'ethers.js': 'ethers.js',
  Foundry: 'Foundry',
  Hardhat: 'Hardhat',
  HQ20: 'HQ20',
  'light.js': 'light.js',
  MUD: 'MUD',
  'OpenZeppelin SDK': 'OpenZeppelin SDK',
  Remix: 'Remix',
  'scaffold-eth': 'scaffold-eth',
  'The Graph': 'The Graph',
  Truffle: 'Truffle',
  TypeChain: 'TypeChain',
  Waffle: 'Waffle',
  'web3-wrapper': 'web3-wrapper',
  'web3.js': 'web3.js',
  Web3j: 'Web3j',
  Other: 'Other',
  None: 'None',
};

export const PROJECT_TECH_BLOCKCHAINS = {
  Aleo: 'Aleo',
  Arbitrum: 'Arbitrum',
  Base: 'Base',
  'Binance Smart Chain': 'Binance Smart Chain',
  Bitcoin: 'Bitcoin',
  Ethereum: 'Ethereum',
  Filecoin: 'Filecoin',
  Loopring: 'Loopring',
  Mantle: 'Mantle',
  Optimism: 'Optimism',
  Polygon: 'Polygon',
  'Polygon zkEVM': 'Polygon zkEVM',
  'Polygon POS': 'Polygon POS',
  Scroll: 'Scroll',
  xDai: 'xDai',
  zkSync: 'zkSync',
  Other: 'Other',
  None: 'None',
};

export const PROJECT_TECH_LANGUAGES = {
  APL: 'APL',
  Assembly: 'Assembly',
  'Bash/Shell': 'Bash/Shell',
  C: 'C',
  'C#': 'C#',
  'C++': 'C++',
  Clojure: 'Clojure',
  COBOL: 'COBOL',
  Crystal: 'Crystal',
  Dart: 'Dart',
  Delphi: 'Delphi',
  Elixir: 'Elixir',
  Erlang: 'Erlang',
  'F#': 'F#',
  Go: 'Go',
  Groovy: 'Groovy',
  Haskell: 'Haskell',
  'HTML/CSS': 'HTML/CSS',
  Java: 'Java',
  JavaScript: 'JavaScript',
  Julia: 'Julia',
  Kotlin: 'Kotlin',
  LISP: 'LISP',
  Matlab: 'Matlab',
  'Node.js': 'Node.js',
  'Objective-C': 'Objective-C',
  Perl: 'Perl',
  PHP: 'PHP',
  PowerShell: 'PowerShell',
  Python: 'Python',
  R: 'R',
  Ruby: 'Ruby',
  Rust: 'Rust',
  Scala: 'Scala',
  Solidity: 'Solidity',
  SQL: 'SQL',
  Swift: 'Swift',
  TypeScript: 'TypeScript',
  VBA: 'VBA',
  Vyper: 'Vyper',
  Other: 'Other',
  None: 'None',
};

export const PROJECT_TECH_WEB_FRAMEWORKS = {
  Angular: 'Angular',
  'Angular.js': 'Angular.js',
  'ASP.NET': 'ASP.NET',
  'ASP.NET Core': 'ASP.NET Core',
  Django: 'Django',
  Drupal: 'Drupal',
  Express: 'Express',
  FastAPI: 'FastAPI',
  Flask: 'Flask',
  Gatsby: 'Gatsby',
  jQuery: 'jQuery',
  Laravel: 'Laravel',
  'Next.js': 'Next.js',
  'React.js': 'React.js',
  'Ruby on Rails': 'Ruby on Rails',
  Spring: 'Spring',
  Svelte: 'Svelte',
  Symfony: 'Symfony',
  'Vue.js': 'Vue.js',
  Other: 'Other',
  None: 'None',
};

export const PROJECT_TECH_DATABASES = {
  AvionDB: 'AvionDB',
  Cassandra: 'Cassandra',
  Couchbase: 'Couchbase',
  DynamoDB: 'DynamoDB',
  Elasticsearch: 'Elasticsearch',
  Ethereum: 'Ethereum',
  Firebase: 'Firebase',
  'IBM DB2': 'IBM DB2',
  IPDB: 'IPDB',
  LevelDB: 'LevelDB',
  MariaDB: 'MariaDB',
  'Microsoft SQL Server': 'Microsoft SQL Server',
  MongoDB: 'MongoDB',
  MySQL: 'MySQL',
  Oracle: 'Oracle',
  OrbitDB: 'OrbitDB',
  PostgreSQL: 'PostgreSQL',
  Redis: 'Redis',
  SQLite: 'SQLite',
  Other: 'Other',
  None: 'None',
};

export const PROJECT_TECH_DESIGN = {
  Figma: 'Figma',
  Flutter: 'Flutter',
  Illustrator: 'Illustrator',
  'MS Paint': 'MS Paint',
  Photoshop: 'Photoshop',
  Sketch: 'Sketch',
  Webflow: 'Webflow',
  Zeppelin: 'Zeppelin',
  Other: 'Other',
  None: 'None',
};
