import { IconProps } from '@devfolioco/source';
import { RSS_TYPES } from '../interfaces/publicProfile';

export const INDIAN_STATES = [
  'Andaman and Nicobar Islands',
  'Andhra Pradesh',
  'Arunachal Pradesh',
  'Assam',
  'Bihar',
  'Chandigarh',
  'Chhattisgarh',
  'Dadra and Nagar Haveli and Daman and Diu',
  'Delhi',
  'Goa',
  'Gujarat',
  'Haryana',
  'Himachal Pradesh',
  'Jammu and Kashmir',
  'Jharkhand',
  'Karnataka',
  'Kerala',
  'Ladakh',
  'Lakshadweep',
  'Madhya Pradesh',
  'Maharashtra',
  'Manipur',
  'Meghalaya',
  'Mizoram',
  'Nagaland',
  'Odisha',
  'Puducherry',
  'Punjab',
  'Rajasthan',
  'Sikkim',
  'Tamil Nadu',
  'Telangana',
  'Tripura',
  'Uttar Pradesh',
  'Uttarakhand',
  'West Bengal',
] as const;

/**
 * @reference https://stackoverflow.com/questions/33865525/indian-pincode-validation-regex-only-six-digits-shouldnt-start-with-0
 */
export const pincodeRegex = /^[1-9][0-9]{5}$/;

/**
 * This regex won't match with a special character at the end
 * @reference https://stackoverflow.com/questions/11757013/regular-expressions-for-city-name
 */
export const onlyCharactersAndAlphabetRegex = /^[a-zA-Z]+(?:[\s-][a-zA-Z]+)*$/;

/**
 * E.164 regex for phone numbers: https://stackoverflow.com/a/68182831/8252081
 * @reference https://stackoverflow.com/a/68182831/8252081
 */
export const phoneNumberRegex = /^\+?[1-9]\d{9,14}$/;

/** The message used for the initial project commit. */
export const INITIAL_COMMIT_MESSAGE = 'Created Project';

/**
 * We prepend the initial commit for hackathon projects.
 * https://github.com/devfolioco/devfolio-backend/pull/41#discussion_r729059109
 */
export const PSUEDO_INITIAL_COMMIT = (createdAt: string) => ({
  uuid: 'pseudoInitialCommit',
  message: INITIAL_COMMIT_MESSAGE,
  createdAt,
  isProjectStateAvailable: false,
});

/** Devfolio logomark URL */
export const DEVFOLIO_LOGO_URL = 'https://raw.githubusercontent.com/devfolioco/brand-assets/master/Logo/Logo%403x.png';

export const DISCUSSION_LIMIT = 10;

export const ALL_MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const SEARCH_RESULT_LIMIT = 10;

export const PERSONAL_WEBSITE_LINK = 'Personal Website';

export const ALLOWED_LINKS = [
  'GitHub',
  'Dribbble',
  'Behance',
  'Stack Overflow',
  'LinkedIn',
  'Kaggle',
  'Twitter',
  'Topcoder',
  PERSONAL_WEBSITE_LINK,
];

/**
 * ALLOWED_PROFILE_LINKS_REGEX has Regular expression to match allowed profile URLs for the website.
 *
 * Allowed:
 * - x.com/elonmusk
 * - x.com/elonmusk/
 *
 * Not Allowed:
 * - x.com
 * - x.com/ajsas/asas
 *
 * @constant {RegExp}
 */

export const ALLOWED_PROFILE_LINKS_REGEX = {
  GitHub: /^((https?:\/\/)?(www\.)?github\.com\/[A-Za-z0-9_-]+\/?)$/,
  Dribbble: /^((https?:\/\/)?(www\.)?dribbble\.com\/[A-Za-z0-9_-]+\/?)$/,
  Behance: /^((https?:\/\/)?(www\.)?behance\.net\/[A-Za-z0-9_-]+\/?)$/,
  'Stack Overflow': /^((https?:\/\/)?(www\.)?stackoverflow\.com\/users\/\d+\/[A-Za-z0-9_-]+\/?)$/,
  LinkedIn: /^((https?:\/\/)?(www\.)?linkedin\.com\/in\/[A-Za-z0-9_-]+\/?)$/,
  Kaggle: /^((https?:\/\/)?(www\.)?kaggle\.com\/[A-Za-z0-9_-]+\/?)$/,
  Twitter: /^((https?:\/\/)?(www\.)?(twitter\.com|x\.com)\/)([A-Za-z0-9_]+\/?$)/,
  Topcoder: /^((https?:\/\/)?(www\.)?topcoder\.com\/members\/[A-Za-z0-9_-]+\/?)$/,
};

export const ALLOWED_LINKS_REGEX_PREFIX = {
  GitHub: /^((https?:\/\/)?(www\.)?github\.com\/)/,
  Dribbble: /^((https?:\/\/)?(www\.)?dribbble\.com\/)/,
  Behance: /^((https?:\/\/)?(www\.)?behance\.net\/)/,
  'Stack Overflow': /^((https?:\/\/)?(www\.)?stackoverflow\.com\/users\/)/,
  LinkedIn: /^((https?:\/\/)?(www\.)?linkedin\.com\/in\/)/,
  Kaggle: /^((https?:\/\/)?(www\.)?kaggle\.com\/)/,
  Twitter: /^((https?:\/\/)?(www\.)?(twitter\.com|x\.com)\/)/,
  Topcoder: /^((https?:\/\/)?(www\.)?topcoder\.com\/members\/)/,
};

export const ALLOWED_LINKS_REGEX = {
  GitHub: new RegExp(`${ALLOWED_LINKS_REGEX_PREFIX.GitHub.source}[a-zA-Z0-9-_]+$`),
  Dribbble: new RegExp(`${ALLOWED_LINKS_REGEX_PREFIX.Dribbble.source}[a-zA-Z0-9-_]+$`),
  Behance: new RegExp(`${ALLOWED_LINKS_REGEX_PREFIX.Behance.source}[a-zA-Z0-9-_]+$`),
  'Stack Overflow': new RegExp(
    `${ALLOWED_LINKS_REGEX_PREFIX['Stack Overflow'].source}[a-zA-Z0-9-_]+(\\/[a-zA-Z0-9-_]+)?$`
  ),
  LinkedIn: new RegExp(`${ALLOWED_LINKS_REGEX_PREFIX.LinkedIn.source}[a-zA-Z0-9-_]+$`),
  Kaggle: new RegExp(`${ALLOWED_LINKS_REGEX_PREFIX.Kaggle.source}[a-zA-Z0-9-_]+$`),
  Twitter: new RegExp(`${ALLOWED_LINKS_REGEX_PREFIX.Twitter.source}[a-zA-Z0-9-_@]+$`),
  Topcoder: new RegExp(`${ALLOWED_LINKS_REGEX_PREFIX.Topcoder.source}[a-zA-Z0-9-_]+$`),
};

export const ALLOWED_LINKS_PREFIX = {
  GitHub: 'github.com/',
  Dribbble: 'dribbble.com/',
  Behance: 'behance.net/',
  'Stack Overflow': 'stackoverflow.com/users/',
  LinkedIn: 'linkedin.com/in/',
  Kaggle: 'kaggle.com/',
  Twitter: 'twitter.com/',
  Topcoder: 'topcoder.com/members/',
};

export const ALLOWED_LINKS_PREFIX_IMAGE = {
  GitHub: '/github-logo-color.svg',
  Dribbble: '/dribble-color.svg',
  LinkedIn: '/linkedin-color.svg',
  Twitter: '/twitter-color.svg',
  'Stack Overflow': '/stackoverflow-color.svg',
  Behance: '/behance-color.svg',
  Kaggle: '/kaggle-color.svg',
  TopCoder: '/topcoder.svg',
};

export const OTHER_LINK_SCOPE = 'other';

export const OTHER_LINK_NAME_PREFIX = `${OTHER_LINK_SCOPE}_`;

// Wait time for debounce fn (in ms)
export const WAIT_TIME = 400;

export const ROLE = {
  GUEST: 'guest',
  HACKER: 'hacker',
  ORGANIZER: 'organizer',
  SQUAD: 'squad',
  ROOT: 'root',
  JUDGE: 'judge',
  OPERATIONS: 'operations',
} as const;

export const SHIRT_SIZE = {
  xs: 'XS',
  s: 'S',
  m: 'M',
  l: 'L',
  xl: 'XL',
  xxl: 'XXL',
  xxxl: 'XXXL',
} as const;

export const SHIRT_SIZE_DETAILS = {
  s: { size: 'S', chest: 38, length: 26 },
  m: { size: 'M', chest: 40, length: 27 },
  l: { size: 'L', chest: 42, length: 28 },
  xl: { size: 'XL', chest: 44, length: 29 },
  xxl: { size: 'XXL', chest: 46, length: 30 },
  xxxl: { size: 'XXXL', chest: 48, length: 31 },
} as const;

export const DEFAULT_SKU_LABEL = 'DEFAULT';

/**
 * To be deprecated with a field in the database
 * in future with a field in the DB.
 */
export const LEADERBOARD_REFERRAL_URLS = {
  ethindia2022: 'https://nsb.dev/ethindia-refer',
  ethforall: 'https://nsb.dev/ethforall-refer',
  wmn2023: 'https://nsb.dev/wmn2023-referral-prizes',
  warpspeed2023: 'https://nsb.dev/warpspeed-referral',
};

export const EAS_SCAN_PREFIX = 'https://arbitrum.easscan.org/attestation/view/';

export const OPEN_SEA_BASE_URL = 'https://opensea.io/assets/';

export const OPEN_SEA_PREFIX = 'https://opensea.io/assets/arbitrum/';

export const BETA_FEATURE_FLAG_UUID = '3310fa89f2a542e6a8142398b5e2fbd1';

export const MAX_PROFILE_FILE_SIZE = 5 * 1024 * 1024; // 5 MB

export const OAUTH_PROVIDERS = {
  GOOGLE: 'google',
  GITHUB: 'github',
  ETHEREUM: 'ethereum',
  DISCORD: 'discord',
  LINKEDIN: 'linkedin',
} as const;

export const RSS_PREFIX: Record<RSS_TYPES, string> = {
  medium: 'medium.com/@',
  hashnode: 'hashnode.com/@',
  custom: '',
};

export const RSS_PREFIX_ICON: Partial<Record<RSS_TYPES, IconProps['name']>> = {
  medium: 'medium',
  hashnode: 'hashnode',
  custom: 'rss',
} as const;

export const DEFAULT_EXPERIENCE_COMPANY_LOGO = '/default_work_experience_icon.svg';

export const PROJECT_CATEGORY = {
  DEFI: 'DeFi',
  NFTS: 'NFTs',
  GAMING: 'Gaming',
  DAO_TOOLING: 'DAO Tooling',
  LAYER_2: 'Layer 2',
  WALLET_PAYMENTS: 'Wallet/Payments',
  DEVELOPER_TOOL: 'Developer Tool',
  DATA_ANALYTICS: 'Data/Analytics',
  AUDIO_VIDEO: 'Audio/Video',
  SOCIAL_NETWORK: 'Social Network',
  ZERO_KNOWLEDGE: 'Zero Knowledge',
  METAVERSE: 'Metaverse',
  OTHER: 'Other',
  DATA_DAO: 'Data DAO',
  LENDING: 'Lending',
  INFRASTRUCTURE: 'Infrastructure',
  STORAGE_APPLICATION: 'Storage Application',
  PRIVACY: 'Privacy',
} as const;

// Feedback options
export const FEEDBACK_OPTIONS = {
  'devfolio-is-no-longer-useful-for-me': 'Devfolio is no longer useful for me',
  'this-was-not-my-primary-devfolio-account': 'This was not my primary Devfolio account',
} as const;

export const NOTIFICATION_EMAIL_DOMAINS = {
  Design: 'Design',
  'Frontend Development': 'Frontend',
  'Backend Development': 'Backend',
  'Mobile Development': 'Mobile',
  Blockchain: 'Blockchain',
} as const;

export const DEVFOLIO_CONTACT_EMAIL = 'hello@devfolio.co';

export const OAUTH_CONNECT_MESSAGES = {
  SUCCESS: 'oauth-connect-success',
  UNKNOWN_FAILURE: 'oauth-connect-failure',
  FAILURE_ACCOUNT_ALREADY_CONNECTED: 'oauth-connect-failure-account-already-connected',
  FAILURE_UNABLE_TO_CONNECT_WALLET: 'oauth-connect-failure-unable-to-connect-wallet',
} as const;

export const ALLOWED_OAUTH_CONNECT_MESSAGES = Object.values(OAUTH_CONNECT_MESSAGES);

export const STAKING_TRANSFER_ADDRESS = '0xde2a501AEa5cf9722a77b03a94c50600D9Dbc2b1';

export const POLYGON_STAKING_CONTRACT_ADDRESS = {
  mainnet: '0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
  testnet: '0xA6FA4fB5f76172d178d61B04b0ecd319C5d1C0aa',
};
